module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"resrcdev","short_name":"resrc","start_url":"/","background_color":"#222222","theme_color":"#042340","display":"minimal-ui","icon":"src/images/resrc-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"87d2af08be9ee8c51c667392e733289f"},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-fathom/gatsby-browser.js'),
      options: {"plugins":[],"trackingUrl":"penguin.sunny.app","siteId":"QDGPYMRN","whitelistHostnames":["resrc.dev","www.resrc.dev"],"embedVersion":"v2"},
    }]
